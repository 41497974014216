var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('CRow',[_c('CCol',{attrs:{"col":"12","lg":"12"}},[_c('CAlert',{attrs:{"color":"danger","closeButton":"","show":_vm.dismissCountDown,"fade":""},on:{"update:show":function($event){_vm.dismissCountDown=$event}}},[_vm._v(" "+_vm._s(_vm.err_msg)+" ")]),_c('CCard',[_c('CCardHeader',[_c('strong',[_vm._v(_vm._s(_vm.$lang.company.crud.create))])]),_c('CForm',{attrs:{"novalidate":""},on:{"submit":function($event){$event.preventDefault();return _vm.onSubmit.apply(null, arguments)}}},[_c('CCardBody',[_c('CRow',[_c('CCol',{attrs:{"md":"6"}},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form__label"},[_vm._v(_vm._s(_vm.$lang.company.form.company_name)+" "),_c('required_span')],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.company.company_name),expression:"company.company_name"}],staticClass:"form-control",attrs:{"maxlength":_vm.maxlength.company_name,"type":"text","placeholder":_vm.$lang.company.form.company_name},domProps:{"value":(_vm.company.company_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.company, "company_name", $event.target.value)}}}),(
                    _vm.$v.company.company_name.$error &&
                    !_vm.$v.company.company_name.required
                  )?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.$lang.company.validation.required.company_name))]):_vm._e()])]),_c('CCol',{attrs:{"md":"6"}},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form__label"},[_vm._v(_vm._s(_vm.$lang.company.form.email)+" "),_c('required_span')],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.company.email),expression:"company.email"}],staticClass:"form-control",attrs:{"type":"text","maxlength":_vm.maxlength.company_name,"placeholder":_vm.$lang.company.form.email},domProps:{"value":(_vm.company.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.company, "email", $event.target.value)}}}),(_vm.$v.company.email.$error && !_vm.$v.company.email.required)?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.$lang.company.validation.required.email))]):_vm._e()])]),_c('CCol',{attrs:{"md":"6"}},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form__label"},[_vm._v(_vm._s(_vm.$lang.company.form.address)+" "),_c('required_span')],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.company.address),expression:"company.address"}],staticClass:"form-control",attrs:{"type":"text","maxlength":_vm.maxlength.address,"placeholder":_vm.$lang.company.form.address},domProps:{"value":(_vm.company.address)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.company, "address", $event.target.value)}}}),(
                    _vm.$v.company.address.$error && !_vm.$v.company.address.required
                  )?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.$lang.company.validation.required.address))]):_vm._e()])]),_c('CCol',{attrs:{"md":"6"}},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form__label"},[_vm._v(_vm._s(_vm.$lang.company.form.contact_no)+" "),_c('required_span')],1),_c('vue-tel-input',_vm._b({attrs:{"maxlength":_vm.maxlength.mobile},on:{"country-changed":_vm.countryChanged},model:{value:(_vm.company.mobile),callback:function ($$v) {_vm.$set(_vm.company, "mobile", $$v)},expression:"company.mobile"}},'vue-tel-input',_vm.bindProps,false)),(
                    _vm.$v.company.mobile.$error && !_vm.$v.company.mobile.required
                  )?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.$lang.company.validation.required.contact_no))]):_vm._e()],1)]),_c('CCol',{attrs:{"md":"6"}},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form__label"},[_vm._v(_vm._s(_vm.$lang.company.form.cp_name)+" "),_c('required_span')],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.company.cp_name),expression:"company.cp_name"}],staticClass:"form-control",attrs:{"type":"text","maxlength":_vm.maxlength.cp_name,"placeholder":_vm.$lang.company.form.cp_name},domProps:{"value":(_vm.company.cp_name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.company, "cp_name", $event.target.value)}}}),(
                    _vm.$v.company.cp_name.$error && !_vm.$v.company.cp_name.required
                  )?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.$lang.company.validation.required.cp_name))]):_vm._e()])]),_c('CCol',{attrs:{"md":"6"}},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form__label"},[_vm._v(_vm._s(_vm.$lang.company.form.cp_contact_no)+" "),_c('required_span')],1),_c('vue-tel-input',_vm._b({attrs:{"maxlength":_vm.maxlength.cpMobile},on:{"country-changed":_vm.cpCountryChanged},model:{value:(_vm.company.cpMobile),callback:function ($$v) {_vm.$set(_vm.company, "cpMobile", $$v)},expression:"company.cpMobile"}},'vue-tel-input',_vm.bindProps,false)),(
                    _vm.$v.company.cpMobile.$error &&
                    !_vm.$v.company.cpMobile.required
                  )?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.$lang.company.validation.required.cp_contact_no))]):_vm._e()],1)]),_c('CCol',{attrs:{"md":"6"}},[_c('div',{staticClass:"form-group"},[_c('label',{staticClass:"form__label"},[_vm._v(_vm._s(_vm.$lang.company.form.cp_email)+" "),_c('required_span')],1),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.company.cp_email),expression:"company.cp_email"}],staticClass:"form-control",attrs:{"type":"text","maxlength":_vm.maxlength.cp_email,"placeholder":_vm.$lang.company.form.cp_email},domProps:{"value":(_vm.company.cp_email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.$set(_vm.company, "cp_email", $event.target.value)}}}),(
                    _vm.$v.company.cp_email.$error &&
                    !_vm.$v.company.cp_email.required
                  )?_c('small',{staticClass:"error"},[_vm._v(_vm._s(_vm.$lang.company.validation.required.cp_email))]):_vm._e()])])],1)],1),_c('CCardFooter',[_c('CButton',{attrs:{"type":"submit","size":"sm","color":"primary","disabled":_vm.submitted}},[_c('CIcon',{attrs:{"name":"cil-check-circle"}}),_vm._v(" "+_vm._s(_vm.$lang.buttons.general.crud.submit)+" ")],1),_c('CButton',{attrs:{"color":"danger","size":"sm"},on:{"click":_vm.cancel}},[_c('CIcon',{attrs:{"name":"cil-ban"}}),_vm._v(" "+_vm._s(_vm.$lang.buttons.general.crud.cancel)+" ")],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }